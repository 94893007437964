@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.form-heading {
  @apply text-[20px] sm:text-[30px] font-semibold text-gray-600 my-4;
}

.form-label {
  @apply text-[14px] text-gray-800 mt-4;
}

.form-input {
  @apply text-[12px] border-[0.5] border-gray-400 p-2 w-full rounded-md;
}

.form-button {
  @apply my-4 text-[13px] bg-sky-800 text-white p-[5px] px-6 hover:bg-transparent rounded-full border hover:border-sky-800 hover:text-sky-800;
}

.form-button-cancel {
  @apply my-4 text-[13px] bg-red-600 text-white p-[5px] px-8 rounded-full hover:bg-transparent border hover:border-red-600 hover:text-red-600;
}

.error-msg {
  @apply text-red-600 text-[12px];
}

.success-msg {
  @apply text-green-700 text-[14px];
}

.arrow {
  @apply text-slate-400 hover:text-slate-500 text-[22px] cursor-pointer;
}
